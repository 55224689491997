html,
body {
  width: 100vw;
  height: 100%;
  color: $c-black;
  font-family: $f-montserrat;
}

.App {
  max-width: 100%;
  height: 100%;
}




