.containerHome, .containerManga, .containerPainting, .containerSculpture, .containerSketch, .containerUnclassified {
    width: 100%;
    background-color: $c-ghostWhite;
    
    .homeCtn, .mangaCtn, .paintingCtn, .sculptureCtn, .sketchCtn, .unclassifiedCtn {
    width: 90%;
    height: 100%;
    background-color: $c-aliceBlue;
    margin: 0 auto;
    box-shadow: $c-charcoalDark 0px 15px 70px -40px;
    }
}


