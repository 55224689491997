// fonts
$f-montserrat: Montserrat, sans-serif;
$f-roboto: Roboto, sans-serif;

// colors
$c-aliceBlue: #e9f1f7;
$c-ghostWhite: #f7f7ff;
$c-charcoalDark: #354a58;
$c-black: #243b4a;
$c-grey: #708895;
$c-richBlack: #030e16;
$c-deepBlack: #08151d;
$c-citrine: #e0ca3c;
$c-moutarde: #ab9610;
