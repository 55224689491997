.emptyCategoryCtn {
    width: 100%;
    min-height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
        display: flex;
        width: 100%;
        height: 50vh;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1199px) {
    .emptyCategoryCtn {
        min-height: calc(100vh - 200px);
    }
}