.loaderCtn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 99999;
    top: 50%;

    .loader {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: linear-gradient(
          217deg,
          rgb(255, 1, 119),
          rgba(0, 102, 255, 0) 70.71%
        ),
        linear-gradient(
          127deg,
          rgb(47, 0, 255),
          rgba(255, 208, 0, 0) 70.71%
        ),
        linear-gradient(
          336deg,
          rgb(255, 205, 3),
          rgba(255, 255, 255, 0) 70.71%
        );
        animation: animate 1s linear infinite;
    
        &::before {
          position: absolute;
          content: "";
          background: $c-aliceBlue;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          height: 90%;
          border-radius: 50%;
        }
    }
}

@media (min-width: 1199px) {
  .loaderCtn .loader {
      width: 150px;
      height: 150px;
    }
}

  
  @keyframes animate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }