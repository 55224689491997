label {
    width: 80px;
    height: 35px;
    position: relative;
    display: inline-block;
    background: #ebebeb;
    border-radius: 200px;
    box-shadow: inset 0px 5px 15px rgba(0,0,0,0.4), inset 0px -5px 15px rgba(255,255,255,0.4);
    cursor: pointer;
    transition: 0.3s;

    &:after {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        top: 2.5px;
        left: 3px;
        background: linear-gradient(180deg,#ffcc89,#d8860b);
        border-radius: 180px;
        box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
        transition: 0.3s;
    }

    &:active:after{
        width: 35px;
    }

    & img {
        position: absolute;
        width: 22px;
        top: 7px;
        z-index: 100;
    }
    
    & img.sun {
        left: 7px;
        fill: #fff;
        transition: 0.3s;
    }
    
    & img.moon {
        left: 51px;
        fill: #7e7e7e;
        transition: 0.3s;
    }
}

input {
    width: 0;
    height: 0;
    visibility: hidden;

    &:checked + label {
        background: #242424;
    }
    
    &:checked + label:after {
        left: 77px;
        transform: translateX(-100%);
        background: linear-gradient(180deg,#777,#3a3a3a);
    }

    &:checked + label + .background {
        background: #242424;
    }
    
    &:checked + label img.sun {
        fill: #7e7e7e;
    }
    
    &:checked + label img.moon {
        fill: #fff;
    }
}