.paginationCtn {
    width: 100%;
    height: 20px;
    margin: 4rem auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: lighten($c-charcoalDark, 20%);

    .paginationElm {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .chevronLeft {
            margin-right: 1rem;
        }

        .chevronRight {
            margin-left: 1rem;
        }

        a {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                color: $c-black;
            }
    
            &.active {
                color: $c-black;
                font-weight: 500;
            }
        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
    .paginationCtn {
        width: 60%;

        .paginationElm {
            .chevronLeft {
                margin-right: 1.5rem;
            }

            .chevronRight {
                margin-left: 1.5rem;
            }

        }
    }
}


@media (min-width: 899px) {
    .paginationCtn {
        width: 50%;

        .paginationElm {
            .chevronLeft {
                margin-right: 2rem;
            }

            .chevronRight {
                margin-left: 2rem;
            }

        }
    }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
    .paginationCtn {
        width: 40%;
    }
}

@media (min-width: 1459px) {
    .paginationCtn {
        width: 30%;
    }
}