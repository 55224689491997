.welcomeCtn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  font-size: 1.5rem;
  line-height: 2rem;
  border-image: none;

  .videoCtn {
    width: 70%;
    height: 325px;
    display: flex;
    align-items: center;
    overflow: hidden;
    box-shadow: none;
    border: none;
    background: none;
    box-decoration-break: none;
    background-color: #fff;

    .video {
      margin: 0 auto;
      width: 65%;
      box-shadow: none;
      border: none;
      background: none;
      box-decoration-break: none;
      background-color: #fff;
    }
  }

  .content {
    width: 85%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .typewriterCtn {
      width: 100%;
      height: 180px;
      font-family: $f-montserrat;
      font-weight: 500;

      .Typewriter {
        width: 100% !important;
        margin: 0 auto !important;

        .Typewriter__wrapper {
          width: 100% !important;
          margin: 0 auto !important;
        }
      }
    }

    .btnCtn {
      width: 85%;

      .btn-welcome {
        width: 100%;
        padding: 0.5rem;
        border-radius: 20px;
        border: none;
        overflow: hidden;
        font-family: $f-montserrat;
        font-weight: 500;
        transition: all 0.5s ease;
        background: transparent;
        background-image: linear-gradient(325deg, #ffa109 0%, #d71195 100%);
        color: $c-ghostWhite;
        font-size: 1.2rem;

        &:hover {
          transform: translate(0, -3px);
          background-image: linear-gradient(325deg, #d02597 0%, #f87a26 100%);
          box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
        }
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  MOBILE --------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 375px) {
  .welcomeCtn {
    align-content: space-around;
    font-size: 1.7rem;
    line-height: 2.1rem;

    .videoCtn {
      .video {
        width: 70%;
      }
    }

    .content {
      min-height: 25%;
    }
  }
}

@media (min-width: 455px) {
  .welcomeCtn .videoCtn .video {
    width: 60%;

    .content {
      min-height: 25%;
    }
  }
}

@media (min-width: 530px) {
  .welcomeCtn .videoCtn .video {
    width: 50%;

    .content {
      min-height: 25%;
    }
  }
}


/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .welcomeCtn {
    align-content: space-around;

    .videoCtn {
      max-width: 40%;
      height: 50%;

      .video {
        width: 70%;
      }
    }

    .content {
      width: 70%;
      min-height: 25%;
      font-size: 1.7rem;
      line-height: 2.1rem;

      .btnCtn {
        width: 60%;

        .btn-welcome {
          padding: 1rem;
          width: 100%;
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media (min-width: 899px) {
  .welcomeCtn {
    font-size: 2rem;
    line-height: 2.5rem;
    width: 90%;
    height: auto;
    margin: auto;
    align-items: center;
    justify-content: space-around;
    display: flex;

    .videoCtn {
      width: 300px;
      height: 500px;
      overflow: hidden;

      .video {
        width: 100%;
      }
    }

    .content {
      width: 500px;
      height: 300px;

      .btnCtn {
        .btn-welcome {
          width: 100%;
          font-size: 1.5rem;
        }
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .welcomeCtn {
    width: 90%;
    height: 100vh;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;

    .videoCtn {
      width: 300px;
      height: 700px;

      .video {
        margin: 0 auto;
        width: 105%;
        background-color: #fff;
      }
    }

    .content {
      width: 700px;
      height: 300px;
      font-size: 2rem;
      line-height: 2.5rem;

      .typewriterCtn {
        width: 100%;
        font-family: $f-montserrat;
        font-weight: 500;

        .Typewriter {
          width: 70% !important;
          margin: 0 auto !important;

          .Typewriter__wrapper {
            width: 100% !important;
            margin: 0 auto !important;
          }
        }
      }

      .btnCtn {
        width: 50%;

        .btn-welcome {
          margin-top: 3rem;
          padding: 0.6rem;
          width: 100%;
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media (min-width: 1499px) {

  .welcomeCtn {
    .videoCtn {
      width: 400px;
    }

    .content {
      width: 700px;
    }
  }
}

@media (min-width: 1699px) {

  .welcomeCtn {
    .videoCtn {
      width: 400px;
    }

    .content {
      width: 800px;
    }
  }
}