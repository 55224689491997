.aboutCtn {
  width: 100%;
  height: auto;
  background-color: $c-aliceBlue;

  .headerCtn {
    margin: 0 auto;

    .navigationFit {
      width: 90%;
      margin: 0 auto;

      .nav {
        margin: 0 auto;

        .linksMobileLarge {
          width: 100vw;
          display: flex;
          margin: 0 auto;
          position: absolute;
          right: -5vw;
        }
      }
    }
  }

  .content {
    width: 90%;
    height: auto;
    min-height: calc(100vh - 180px);
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-start: 1;
    padding: 1rem 0;
    margin: 0 auto;

    // style global aux images
    .position {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
    }

    //& ----------------------- SECTION 1 ------------------------

    .firstSection {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 2rem;

      .illustration1 {
        width: 300px;
        height: 300px;
        position: relative;
        margin: 3rem auto 2rem auto;

        .palette {
          width: 60%;
          height: 60%;
          background-image: url("../../images/palette.png");
          animation: balance 3s infinite;
          animation-direction: alternate;
          left: 3rem;
          top: 10rem;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          position: relative;

          .brush {
            width: 40%;
            height: 40%;
            background-image: url("../../images/brush.png");
            animation: write 0.5s infinite;
            animation-direction: alternate;
            top: 1.5rem;
            left: 9rem;
            z-index: 15;
          }

          .redTube1 {
            width: 35%;
            height: 35%;
            background-image: url("../../images/red-tube1.png");
            animation: antitube 2s infinite;
            animation-direction: alternate;
            top: -4rem;
            left: 5rem;
          }

          .redTube2 {
            width: 35%;
            height: 35%;
            background-image: url("../../images/red-tube2.png");
            animation: press 2.5s infinite;
            animation-direction: alternate;
            top: 0;
            left: 0;
            z-index: 15;
          }

          .greenTube {
            width: 35%;
            height: 35%;
            background-image: url("../../images/green-tube.png");
            animation: shake 0.2s infinite;
            animation-direction: alternate;
            top: -5rem;
            left: 0rem;
          }

          .blueTube {
            width: 35%;
            height: 35%;
            background-image: url("../../images/blue-tube.png");
            animation: antitube 1.7s infinite;
            animation-direction: alternate;
            top: -10rem;
            left: 6rem;
          }

          .purpleTube {
            width: 35%;
            height: 35%;
            background-image: url("../../images/purple-tube.png");
            animation: tube 1.2s infinite;
            animation-direction: alternate;
            top: -11rem;
            left: 2rem;
          }
        }
      }

      .firstDescription {
        width: 300px;
        color: lighten($c-charcoalDark, 20%);
        font-size: 1rem;
        line-height: 2rem;

        .titleCtn {
          width: 100%;
          padding: 2rem 0;
          color: $c-charcoalDark;
          z-index: 0;
          position: relative;

          .title {
            width: 80%;
            text-transform: uppercase;
            font-size: 2rem;
            line-height: 2rem;
            z-index: 5;
          }

          .subTitle {
            font-weight: 900;
            text-transform: uppercase;
            color: $c-aliceBlue;
            font-size: 3rem;
            text-shadow: 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine;
            z-index: -1;
            margin-top: -3.5rem;
            position: absolute;
          }
        }
      }
    }

    //& ----------------------- SECTION 2 ------------------------

    .secondSection {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .secondDescription {
        width: 300px;
        color: lighten($c-charcoalDark, 20%);
        font-size: 1rem;
        line-height: 2rem;

        .titleCtn {
          width: 100%;
          padding: 2rem 0;
          color: $c-charcoalDark;
          position: relative;
          z-index: 0;

          .title {
            width: 80%;
            text-transform: uppercase;
            font-size: 2rem;
            z-index: 5;
          }

          .subTitle {
            font-weight: 900;
            text-transform: uppercase;
            color: $c-aliceBlue;
            font-size: 3rem;
            text-shadow: 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine;
            z-index: -1;
            margin-top: -3rem;
            position: absolute;
          }
        }
      }

      .illustration2 {
        width: 300px;
        height: 300px;
        position: relative;
        margin: 7rem auto 0 auto;

        .easel {
          width: 300px;
          height: 300px;
          background-image: url("../../images/easel.png");
          left: 0rem;
          bottom: 3rem;
          z-index: 0;
        }

        .rainbow {
          width: 100px;
          height: 100px;
          background-image: url("../../images/rainbow.png");
          animation: paint 2s infinite;
          top: 0.5rem;
          left: 6rem;
          z-index: 2;
        }

        .stain1 {
          width: 50px;
          height: 50px;
          background-image: url("../../images/stain1.png");
          animation: splash 1s infinite;
          animation-delay: 1.75s;
          top: 1rem;
          left: 13rem;
          z-index: 1;
        }

        .stain2 {
          width: 50px;
          height: 50px;
          background-image: url("../../images/stain2.png");
          animation: splash 1s infinite;
          animation-delay: 1s;
          top: 3.5rem;
          left: 13rem;
          z-index: 1;
        }

        .stain3 {
          width: 50px;
          height: 50px;
          background-image: url("../../images/stain3.png");
          animation: splash 1s infinite;
          animation-delay: 1.5s;
          top: 0.5rem;
          left: 3rem;
          z-index: 1;
        }
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  MOBILE --------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 375px) {
  .aboutCtn .content {
    .firstSection .firstDescription {
      width: 350px;
    }

    .secondSection .secondDescription {
        width: 350px;
    }
}
}

@media (min-width: 455px) {
  .aboutCtn .content {
    .firstSection .firstDescription {
      width: 400px;
    }

    .secondSection .secondDescription {
        width: 400px;
    }
}
}


@media (min-width: 479px) {

  .aboutCtn .content {
      .firstSection .firstDescription {
        width: 450px;
      }
  
      .secondSection .secondDescription {
          width: 450px;
      }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .aboutCtn .content   {
    .firstSection .illustration1 {
      margin: 5rem auto;
    }

    .firstSection .firstDescription {
      width: 450px;
    }

    .secondSection  {
      justify-content: flex-end;

      .secondDescription {
        width: 450px;
        display: flex;
        flex-direction: column;
        text-align: end;

        .titleCtn {
          .title {
            width: 100%;
          }

          .subTitle {
            margin-top: -3rem;
            right: 0;
          }
        }
      }
    }
  }
}

@media (min-width: 899px) {
  .aboutCtn {
    .content {
      padding: 4rem 0 12rem 0;
      //& ----------------------- SECTION 1 ------------------------

      .firstSection {
        .illustration1 {
          width: 300px;
          height: 300px;
        }

        .firstDescription {
          width: 450px;
          font-size: 1rem;
          line-height: 2rem;
        }
      }

      //& ----------------------- SECTION 2 ------------------------

      .secondSection {
        .secondDescription {
          width: 450px;
        }

        .illustration2 {
          margin: 3rem auto 0 auto;
        }
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .aboutCtn {
    .headerCtn {
      .navigationFit {
        width: 90%;
      }
    }

    .content {
      padding: 3.5rem 0;
      min-height: calc(100vh - 200px);

      //& ----------------------- SECTION 1 ------------------------

      .firstSection {
        .illustration1 {
          width: 350px;
          height: 300px;
          margin: 8rem 0 0 -6rem;

          .palette {
            left: 12rem;
            top: 5rem;

            .brush {
              top: 1rem;
              left: 10.5rem;
            }

            .redTube1 {
              top: -5rem;
              left: 6rem;
            }

            .redTube2 {
              top: 0;
              left: 0;
            }

            .greenTube {
              top: -5rem;
              left: 0;
            }

            .blueTube {
              top: -11rem;
              left: 6rem;
            }

            .purpleTube {
              top: -14rem;
              left: 3rem;
            }
          }
        }

        .firstDescription {
          width: 650px;
          font-size: 1.5rem;
          line-height: 2.5rem;
          margin-top: 1rem;

          .titleCtn {
            margin: 1rem 0;

            .title {
              font-size: 3rem;
            }

            .subTitle {
              font-size: 4rem;
              margin-top: -4rem;
            }
          }
        }
      }

      //& ----------------------- SECTION 2 ------------------------

      .secondSection {
        justify-content: space-between;

        .secondDescription {
          width: 650px;
          font-size: 1.5rem;
          line-height: 2.5rem;

          .titleCtn {
            .title {
              font-size: 3rem;
            }

            .subTitle {
              font-size: 4rem;
              margin-top: -4rem;
            }
          }
        }

        .illustration2 {
          width: 350px;
          height: 450px;

          .easel {
            width: 400px;
            height: 400px;
            left: 0rem;
            bottom: 3rem;
          }

          .rainbow {
            width: 120px;
            height: 120px;
            top: 5rem;
            left: 9rem;
          }

          .stain1 {
            width: 70px;
            height: 70px;
            top: 6rem;
            left: 16rem;
          }

          .stain2 {
            width: 60px;
            height: 60px;
            top: 9rem;
            left: 17rem;
          }

          .stain3 {
            width: 60px;
            height: 60px;
            top: 5rem;
            left: 4rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1389px) {
  .aboutCtn .content .firstSection .illustration1 {
    width: 400px;
    height: 350px;
    margin: 10rem 0 0 -8rem;

    .palette {
      .brush {
        top: 1.5rem;
        left: 12rem;
      }

      .redTube1 {
        top: -6rem;
        left: 7rem;
      }

      .greenTube {
        top: -6.5rem;
        left: 0;
      }

      .blueTube {
        top: -13rem;
        left: 7rem;
      }

      .purpleTube {
        top: -16rem;
        left: 3rem;
      }
    }
  }

  .aboutCtn .content .secondSection .illustration2 {
    width: 400px;
  }
}

@media (min-width: 1429px) {
  .aboutCtn .content .firstSection .illustration1 {
    margin: 10rem 0 0 0rem;
  }
}

@media (min-width: 1499px) {
  .aboutCtn .content {
    .firstSection {
      .illustration1 {
        width: 420px;
        height: 400px;
        margin: 10rem 0 0 -5rem;
    
        .palette {
          .brush {
            left: 12.5rem;
          }
    
          .greenTube {
            top: -7rem;
          }
    
          .blueTube {
            left: 8rem;
          }
        }
      }

      .firstDescription {
        width: 750px;
        font-size: 1.5rem;
        line-height: 2.5rem;
        margin-top: 1rem;

        .titleCtn {
          margin: 1rem 0;

          .title {
            font-size: 3rem;
          }

          .subTitle {
            font-size: 4rem;
            margin-top: -4rem;
          }
        }
      }
    }

    .secondSection {
      .secondDescription {
        width: 750px;
      }

      .illustration2 {
        width: 420px;
      }
    }
  }
}

@media (min-width: 1555px) {
  .aboutCtn .content {
    padding: 5rem 0;

    .firstSection .illustration1 {
      width: 600px;
      height: 550px;
      margin: 13rem 0 0 0;
  
      .palette {
        .brush {
          top: 2rem;
          left: 18rem;
        }
  
        .redTube1 {
          top: -7rem;
          left: 10rem;
        }
  
        .greenTube {
          top: -9.5rem;
          left: 0;
        }
  
        .blueTube {
          top: -17rem;
          left: 13rem;
        }
  
        .purpleTube {
          top: -20rem;
          left: 6rem;
        }
      }
    }

    .secondSection {
      .illustration2 {
        width: 600px;
        margin: 0;

        .easel {
          width: 450px;
          height: 450px;
          left: 0rem;
          bottom: 3rem;
        }

        .rainbow {
          width: 150px;
          height: 150px;
          top: 1.5rem;
          left: 9rem;
        }

        .stain1 {
          width: 100px;
          height: 100px;
          top: 1rem;
          left: 18rem;
        }

        .stain2 {
          width: 100px;
          height: 100px;
          top: 5rem;
          left: 17rem;
        }

        .stain3 {
          width: 100px;
          height: 100px;
          top: 1rem;
          left: 3rem;
        }

      }
    }
    
  }
}

//& ---------------------------------------- ANIMATIONS ---------------------------------------- //

@keyframes shake {
  0% {
    transform: rotateZ(-2deg);
  }
  100% {
    transform: rotateZ(2deg);
  }
}

@keyframes balance {
  0% {
    transform: rotateZ(-2deg);
  }
  100% {
    transform: rotateZ(2deg);
  }
}

@keyframes write {
  0% {
    transform: rotateZ(-5deg);
  }
  100% {
    transform: rotateZ(5deg);
  }
}

@keyframes paint {
  0% {
    transform: rotateZ(120deg);
  }
  100% {
    transform: rotateZ(460deg);
  }
}

@keyframes tube {
  0% {
    transform: translateY(10px) rotate(0deg);
  }
  100% {
    transform: translateY(-10px) rotate(10deg);
  }
}

@keyframes antitube {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(20px) rotate(-10deg);
  }
}

@keyframes press {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  100% {
    transform: translateY(10px) rotate(-10deg);
  }
}

@keyframes splash {
  0% {
    opacity: 0;
    scale: 1.5;
  }
  25% {
    opacity: 1;
    scale: 1.2;
  }
  50% {
    opacity: 0;
    scale: 1;
  }
  75% {
    opacity: 0;
    scale: 1.3;
  }
  100% {
    opacity: 1;
    scale: 1.5;
  }
}
