.gridCtn {
  width: 90%;
  height: 100%;
  background-color: $c-aliceBlue;
  margin: 0 auto;
  padding: 1rem 0 3rem 0;
  min-height: calc(100vh - 180px);

  .galery {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 250px);
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-gap: 20px;
    justify-content: center;
    align-content: start;
    justify-items: center;
    margin-top: 20px;

    .imageCtnList {
      cursor: pointer;
    }

    .imageCtnList {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem 0;
      position: relative;

      .picture {
        border: solid 1rem $c-ghostWhite;
        border-bottom: 3rem solid $c-ghostWhite;
        box-shadow: $c-charcoalDark 0px 15px 30px -20px;
        max-width: 100%;
        margin: 0 auto;
        width: 100%;
        object-fit: cover;
        display: block;
        height: 100%;
        cursor: pointer;
      }

      .description,
      .fa-eye, .title {
        font-family: $f-roboto;
        font-size: 1rem;
        line-height: 1.3rem;
        position: absolute;
        display: block;
        right: 2rem;
        left: 2rem;
        text-align: center;
        width: 80%;
        margin: auto;
        color: $c-ghostWhite;
        z-index: 100;
        opacity: 0;
        transform: translate3d(0, -50%, 0);
        transition: 0.3s 0s;
        cursor: pointer;
      }

      .title {
        top: 20%;
        font-weight: bold;
        font-size: 1.25rem;
      }

      .description {
        top: 50%;
      }

      .fa-eye {
        width: 10%;
        font-size: 1.5rem;
        top: calc(90% - 3rem);
      }

      &:hover .description {
        opacity: 1;
        transition: 0.5s ease-in-out;
      }

      &:hover .title {
        opacity: 1;
        transition: 0.5s ease-in-out;
      }

      &:hover .fa-eye {
        opacity: 1;
        transition: 0.5s ease-in-out;
      }

      &:after,
      &:before {
        content: "";
        position: absolute;
        width: calc(100% - 2rem);
        height: calc(100% - 4rem);
        bottom: 3rem;
        opacity: 0;
      }

      &:after {
        mix-blend-mode: multiply;
        background: $c-grey;
      }

      &:before {
        backdrop-filter: grayscale(100%) blur(2px);
        -webkit-backdrop-filter: grayscale(100%) blur(2px);
      }

      &:hover:after,
      &:hover:before {
        transition: 0.5s ease-in-out;
        opacity: 1;
      }
    }
  }
}


/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .gridCtn {
    width: 75%;
    height: 100%;
    background-color: $c-aliceBlue;
    margin: 0 auto;
    padding: 3rem 0 5rem 0;
    min-height: calc(100vh - 200px);

    .galery {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      grid-gap: 20px;
      margin-top: 20px;

      .column-1,
      .column-2,
      .column-3 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-auto-flow: row;
        gap: 20px;
        justify-content: center;
        align-content: start;
        justify-items: center;

        .picture {
          border: solid 1rem $c-ghostWhite;
          border-bottom: 3rem solid $c-ghostWhite;
          box-shadow: $c-charcoalDark 0px 15px 30px -20px;
        }
      }
    }
  }
}
