.footerCtn {
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 1rem;
  border-top: solid 0.5px lighten($c-charcoalDark, 60%);
  position: relative;
  grid-row-start: 3;

  .links {
    width: 100%;
    text-align: center;
    transition: all 0.5s;

    .gtcu {
      transition: all 0.2s;

      &:hover {
        font-weight: bold;
      }
    }
  }

  .copyCtn {
    width: 100%;

    .copyright {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

}

@media (min-width: 1199px) {
  .footerCtn  {
    height: 200px;
  }
}


