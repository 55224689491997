.gtcuCtn {
  width: 100%;
  height: auto;
  background-color: $c-aliceBlue;

  .headerCtn {
    margin: 0 auto;

    .navigationFit {
      width: 90%;
      margin: 0 auto;

      .nav {
        margin: 0 auto;

        .linksMobileLarge {
          width: 100vw;
          display: flex;
          margin: 0 auto;
          position: absolute;
          right: -5vw;
        }
      }
    }
  }

  .content {
    width: 90%;
    grid-row-start: 1;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 3rem;
    margin: 0 auto;
    color: lighten($c-charcoalDark, 20%);
    font-size: 1.5rem;
    line-height: 2.5rem;

    .titleCtn {
      width: 100%;
      padding: 2rem 0;
      color: $c-charcoalDark;
      position: relative;
      z-index: 0;

      .title {
        width: 85%;
        text-transform: uppercase;
        font-size: 2rem;
        line-height: 2.5rem;
        z-index: 5;
      }

      .title2 {
        text-transform: uppercase;
        font-size: 1.3rem;
        margin-top: 1rem;
      }

      .subTitle {
        width: 100%;
        font-weight: 900;
        text-transform: uppercase;
        color: $c-aliceBlue;
        font-size: 2.5rem;
        text-shadow: 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine;
        z-index: -1;
        margin-top: -10.5rem;
        position: absolute;
      }
    }

    .gtcuText {
      font-size: 1rem;
      line-height: 2rem;
      margin: 1rem 0;
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  MOBILE --------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 375px) {
  .gtcuCtn .content .titleCtn .subTitle {
    font-size: 2.3rem;
    margin-top: -11rem;
  }
}

@media (min-width: 414px) {
  .gtcuCtn .content .titleCtn .subTitle {
    font-size: 2.3rem;
    margin-top: -8.5rem;
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .gtcuCtn {
    .content {
      padding: 2rem 0;

      .titleCtn {
        .title {
          font-size: 1.7rem;
          line-height: 2.2rem;
        }

        .title2 {
          font-size: 1.5rem;
        }

        .subTitle {
          font-size: 2rem;
          margin-top: -5.5rem;
        }
      }

      .gtcuText {
        font-size: 1.3rem;
        line-height: 2.3rem;
        margin: 2rem 0;
      }
    }
  }
}

@media (min-width: 899px) {
  .gtcuCtn {
    .content {
      min-height: calc(100vh - 180px);
      padding-top: 3rem;
      align-content: flex-start;

      .titleCtn {
        height: 150px;

        .subTitle {
          font-size: 2.5rem;
          margin-top: -3.5rem;
        }
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .gtcuCtn {
    .headerCtn {
      .navigationFit {
        width: 90%;
        margin: 0 auto;
      }
    }

    .content {
      padding: 5rem 0;
      min-height: calc(100vh - 200px);

      .titleCtn {
        height: 200px;

        .title {
          font-size: 3rem;
          line-height: 3.5rem;
        }

        .title2 {
          font-size: 2rem;
          margin-top: 1rem;
        }

        .subTitle {
          font-size: 3.5rem;
          margin-top: -7.5rem;
        }
      }

      .gtcuText {
        font-size: 1.5rem;
        line-height: 2.5rem;
      }
    }
  }
}

@media (min-width: 1401px) {
  .gtcuCtn {
    .content {
      padding: 5rem 0;

      .titleCtn {
        .subTitle {
          font-size: 3rem;
          margin-top: -4.5rem;
        }
      }
    }
  }
}
