.dark {
  color: $c-ghostWhite;
}


.dark .containerHome, .dark .containerManga, .dark .containerPainting, .dark .containerSketch, .dark .containerSculpture, .dark .containerUnclassified {
    background-color: $c-richBlack;

    .homeCtn, .mangaCtn, .paintingCtn, .sketchCtn, .sculptureCtn, .unclassifiedCtn {
        background-color: $c-deepBlack;
        box-shadow: $c-ghostWhite 0px 15px 70px -40px;
    }
}

.dark .loaderCtn .loader {
  &::before {
    background: $c-deepBlack;
  }
}

//*--------------------------------------  WELCOME  --------------------------------------------
.dark .welcomeCtn .content {
  color: $c-black;
}

//*--------------------------------------  HEADER  --------------------------------------------

.dark .headerCtn {
  background-color: $c-deepBlack;
  border-bottom: solid 0.5px $c-charcoalDark;
  box-shadow: $c-aliceBlue 0px -5px 70px -40px;
}

.dark .headerCtn  {
     .navigation .logo {
         .logoLink {
           .logoImg {
            background-image: url("../../images/logo-dark.png");
          }
        }
      }

     .navigation .nav .links .link {
         .active {
            color: $c-aliceBlue;
            border-bottom: solid 2px lighten($c-charcoalDark, 5%);
            font-weight: 200;
        }

         .linkItem {
            color: $c-aliceBlue;
            font-weight: 200;
        
            &:hover {
                color: $c-aliceBlue;
            
                &::after {
                    background-color: lighten($c-charcoalDark, 5%);
                }
            }
        }
    }

    .navigation .nav {
        .linksMobile {
          background-color: $c-deepBlack;

          .link .linkItem  {
              color: $c-aliceBlue;

              &:hover {
                color: $c-black;
              }

              &:hover::after {
                background-color: lighten($c-charcoalDark, 50%);
              }
          }

          .link .active {
            color: $c-aliceBlue;
            border-bottom: solid 2px lighten($c-charcoalDark, 5%);
          }
        }

        .instagram .instagramLink .instagramLogo .fa-instagram {
          color: $c-ghostWhite;
          
          &:hover {
              box-shadow: $c-aliceBlue 0px 25px 20px -20px;
          }
        }

        .burgerMenuCtn .burgerMenu {
          background-image: url("../../images/burger-menu-light.svg");
        }
    }
}

//*--------------------------------------  GRID  --------------------------------------------

.dark .gridCtn {
    background-color: $c-deepBlack;
    
    .galery .imageCtnList .picture {
        border: solid 1rem $c-richBlack;
        border-bottom: 3rem solid $c-richBlack;
        box-shadow: $c-ghostWhite 0px 15px 30px -20px;
    }

    .galery .imageCtnList {
        &:after {
            background: $c-charcoalDark;
        }
    }

    .paginationCtn {
      color: lighten($c-charcoalDark, 45%);
  
      .paginationElm a {
        &:hover {
            color: $c-aliceBlue;
        }

        &.active {
            color: $c-aliceBlue;
        }
      }
  }
}

//*--------------------------------------  FOOTER  --------------------------------------------

.dark .footerCtn {
    border-top: solid 0.5px $c-charcoalDark;
  }

//*-----------------------------------  SCROLL TO TOP  ----------------------------------------

.dark .scrollToTopBtn {
    background-color: $c-aliceBlue;

    &:hover {
      background-color: $c-charcoalDark;

      .icon {
        color: $c-aliceBlue;
      }
    }

    .icon {
      color: $c-moutarde;
    }
  }

//*-----------------------------------  ABOUT ME  ------------------------------------------

.dark .aboutCtn {
    background-color: $c-deepBlack;

      .content .firstSection .firstDescription, .content .secondSection .secondDescription {
          color: lighten($c-charcoalDark, 40%);
    
          .titleCtn {
            color: $c-aliceBlue;
    
            .subTitle {
              color: $c-deepBlack;
              text-shadow: 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde;
            }
          }
      }
  }

//*-----------------------------------  CONTACT  ------------------------------------------

.dark .contactCtn {
    background-color: $c-deepBlack;

    .content {
      .instagram .instagramLogo .fa-instagram {
          color: $c-ghostWhite;

          &:hover {
            color: $c-charcoalDark;
          }
      }

      .contactDescription {
        color: lighten($c-charcoalDark, 40%);

        .titleCtn {
          color: $c-aliceBlue;

          .subTitle {
            color: $c-deepBlack;
            text-shadow: 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde, 0 0 0.7px $c-moutarde;
          }
        }
      }
    }
  }

//*-------------------------------------  GTCU  --------------------------------------------

.dark .gtcuCtn {
  background-color: $c-deepBlack;

  .content {
    color: lighten($c-charcoalDark, 40%);

    .titleCtn {
      color: $c-aliceBlue;

      .subTitle {
        color: $c-deepBlack;
      }
    }
  }
}