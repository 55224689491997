.headerCtn {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  border-bottom: solid 0.5px lighten($c-charcoalDark, 60%);
  box-shadow: $c-charcoalDark 0px -5px 70px -40px;
  position: relative;
  background-color: $c-aliceBlue;
  margin: 0 auto;
  z-index: 1000;
  position: sticky;
  top: 0;

  .navigation {
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo {
      width: 200px;
      height: 100%;
      display: flex;

      .logoLink {
        width: 100%;
        height: 100px;

        .logoImg {
          width: 100%;
          height: 100%;
          background-image: url("../../images/logo-light.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .nav {
      width: 100%;
      height: 100%;
      position: relative;

      .linksMobile {
        width: 90vw;
        height: calc(100vh - 120px);
        position: absolute;
        top: 120px;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        background-color: $c-aliceBlue;
        z-index: 1000;

        .toggleThemeCtn {
          position: absolute;
          right: 1rem;
          top: 1rem;
        }

        .link {
          margin: 1rem 0;

          .linkItem {
            color: $c-charcoalDark;
            position: relative;
            transition: all 300ms;
            font-size: 1rem;

            &:hover {
              color: $c-black;
            }

            &::after {
              content: "";
              position: absolute;
              width: 0%;
              height: 5px;
              display: block;
              transition: all 0.3s ease;
            }

            &:hover::after {
              width: 100%;
              height: 1px;
              background-color: lighten($c-charcoalDark, 50%);
            }
          }

          .active {
            color: $c-black;
            border-bottom: solid 2px lighten($c-charcoalDark, 50%);
            font-size: 1rem;
          }
        }
      }

      .links {
        display: none;
      }

      .instagram {
        display: block;
        width: 60px;
        position: absolute;
        top: 0.5rem;
        right: 1rem;

        .instagramLink {
          width: 100%;

          .instagramLogo {
            width: 100%;
            height: 30%;
            background: none;

            .fa-instagram {
              font-size: 2.5rem;
              color: $c-ghostWhite;
              background: linear-gradient(
                  217deg,
                  rgb(255, 1, 119),
                  rgba(255, 208, 0, 0) 70.71%
                ),
                linear-gradient(
                  127deg,
                  rgb(47, 0, 255),
                  rgba(255, 208, 0, 0) 70.71%
                ),
                linear-gradient(
                  336deg,
                  rgb(255, 163, 3),
                  rgba(255, 255, 255, 0) 70.71%
                );
              border-radius: 10px;
              padding: 0.3rem 0.45rem;
              transition: all 0.5s ease;

              &:hover {
                transform: translate(0, -1px);
                box-shadow: $c-charcoalDark 0px 25px 20px -20px;
                background: linear-gradient(
                    336deg,
                    rgb(240, 30, 128),
                    rgba(255, 208, 0, 0) 70.71%
                  ),
                  linear-gradient(
                    127deg,
                    rgb(241, 165, 34),
                    rgba(255, 208, 0, 0) 70.71%
                  ),
                  linear-gradient(
                    217deg,
                    rgb(15, 22, 234),
                    rgba(255, 255, 255, 0) 70.71%
                  );
              }
            }
          }
        }
      }

      .burgerMenuCtn {
        padding: 0;
        display: block;

        .burgerMenu {
          width: 50px;
          height: 50px;
          position: absolute;
          top: 4rem;
          right: 0.5rem;
          z-index: 10;
          background-image: url("../../images/burger-menu-dark.svg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  MOBILE --------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 375px) {
  .headerCtn .navigation .nav .burgerMenuCtn .burgerMenu {
    right: 0.75rem;
  }
}

@media (min-width: 455px) {
  .headerCtn .navigation .nav .burgerMenuCtn .burgerMenu {
    right: 1rem;
  }
}

@media (min-width: 479px) {
  .headerCtn .navigation .nav .burgerMenuCtn .burgerMenu {
    right: 1.5rem;
  }
}

@media (min-width: 559px) {
  .headerCtn {
    .navigation {
      .nav {
        .burgerMenuCtn .burgerMenu {
          right: 2rem;
        }

        .linksMobile .link {
          .linkItem {
            font-size: 1.3rem;
          }

          .active {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .headerCtn {
    font-size: 1.1rem;

    .navigation .nav .linksMobile .link .active {
      font-size: 1.3rem;
    }
  }
}

@media (min-width: 645px) {
  .headerCtn .navigation .nav .instagram {
    right: 2.5rem;
  }
}

@media (min-width: 759px) {
  .headerCtn .navigation .nav .instagram  {
    right: 2rem;
  }
}

@media (min-width: 829px) {
  .headerCtn .navigation .nav .instagram {
    right: 1.5rem;
  }
}

@media (min-width: 899px) {
  .headerCtn {
    font-size: 1.2rem;

    .navigation {
      .nav {
        .linksMobile {
          .link {
            .linkItem {
              font-size: 1.5rem;
            }

            .active {
              font-size: 1.5rem;
            }
          }
        }

        .instagram {
          right: 1rem;
        }
      }


    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .headerCtn {
    font-size: 1rem;
    width: 100%;
    height: 150px;

    .navigation {
      width: 100%;
      height: 55%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        margin: 1rem 0;
        width: 150px;
      }
      
      .nav {
        width: 60%;

        .linksMobile {
          display: none;
        }

        .links {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          margin-right: 30px;
          position: relative;
          transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

          .toggleThemeCtn {
            position: absolute;
            right: -7rem;
            top: -1rem;
          }

          .link {
            .linkItem {
              color: $c-charcoalDark;
              position: relative;
              transition: all 300ms;

              &:hover {
                color: $c-black;
              }

              &::after {
                content: "";
                position: absolute;
                width: 0%;
                height: 5px;
                display: block;
                transition: all 0.3s ease;
              }

              &:hover::after {
                width: 100%;
                height: 1px;
                background-color: lighten($c-charcoalDark, 50%);
              }
            }

            .active {
              color: $c-black;
              border-bottom: solid 2px lighten($c-charcoalDark, 50%);
            }
          }
        }

        .instagram {
          top: -6rem;
          right: -7rem;
        }

        .burgerMenuCtn {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 1499px) {
  .headerCtn {
    height: 180px;

    .navigation {
      height: 90%;

      .logo {
        margin: 1rem 0;
        width: 200px;
      }

      .nav {
        width: 50%;
  
        .instagram {
          right: -8rem;
        }

        .links {

          .toggleThemeCtn {
            right: -8rem;
            top: -0.5rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1699px) {
  .headerCtn .navigation .nav {
    width: 45%;
  }
}
