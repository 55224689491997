.contactCtn {
  width: 100%;
  height: auto;
  background-color: $c-aliceBlue;

  .headerCtn {
    margin: 0 auto;

    .navigationFit {
      width: 90%;
      margin: 0 auto;

      .nav {
        margin: 0 auto;

        .linksMobileLarge {
          width: 100vw;
          display: flex;
          margin: 0 auto;
          position: absolute;
          right: -5vw;
        }
      }
    }
  }



  .content {
    width: 90%;
    grid-row-start: 1;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 auto;

    .instagram {
      width: 200px;
      height: 200px;
      margin: 1rem auto;

      .instagramLogo {
        width: 100%;
        height: 100%;
        background-color: transparent;
        position: relative;
        z-index: 0;
        overflow: hidden;
        border-radius: 30px;
        display: block;
        margin: auto;

        &::after {
          content: "";
          position: absolute;
          width: 300px;
          height: 300px;
          top: -3rem;
          left: -3rem;
          z-index: -1;
          display: block;
          animation: spine 5s infinite linear;
          background: linear-gradient(
              217deg,
              rgb(255, 1, 119),
              rgba(0, 102, 255, 0) 70.71%
            ),
            linear-gradient(
              127deg,
              rgb(47, 0, 255),
              rgba(255, 208, 0, 0) 70.71%
            ),
            linear-gradient(
              336deg,
              rgb(255, 205, 3),
              rgba(255, 255, 255, 0) 70.71%
            );
        }

        .fa-instagram {
          font-size: 10rem;
          color: $c-ghostWhite;
          transition: all 0.5s ease-in-out;

          &:hover {
            color: $c-charcoalDark;
          }
        }

        @keyframes spine {
          0% {
            transform: rotateZ(0deg);
          }
          100% {
            transform: rotateZ(360deg);
          }
        }
      }

      .pseudo {
        margin: 1rem 0;
        display: block;
        width: 100%;
        font-size: 1rem;
        text-align: center;
        border-bottom: 2px solid transparent;

        &:hover {
          font-weight: 500;
        }
      }
    }

    .contactDescription {
      width: 95%;
      color: lighten($c-charcoalDark, 20%);
      font-size: 1rem;
      line-height: 2rem;
      margin-top: 3rem;

      .titleCtn {
        width: 100%;
        padding: 1rem 0;
        color: $c-charcoalDark;
        position: relative;
        z-index: 0;
        margin-bottom: 1rem;

        .title {
          width: 100%;
          text-transform: uppercase;
          font-size: 2rem;
          z-index: 5;
        }

        .subTitle {
          width: 100%;
          font-weight: 900;
          text-transform: uppercase;
          color: $c-aliceBlue;
          font-size: 2.5rem;
          text-shadow: 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine, 0 0 0.7px $c-citrine;
          z-index: -1;
          margin-top: -3rem;
          position: absolute;
        }
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  MOBILE --------------------------------------
----------------------------------------------------------------------------------*/
@media (min-width: 320px) {
  .contactCtn .content .contactDescription .titleCtn .subTitle {
    font-size: 1.9rem;
    width: 100%;
  }
}

@media (min-width: 414px) {
  .contactCtn .content .contactDescription .titleCtn .subTitle {
    font-size: 2.5rem;
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .contactCtn {
    .headerCtn .navigationFit {
      margin: 0 auto;
    }

    .content {
      padding: 2rem 0;

      .instagram {
        width: 400px;
        height: 400px;
        margin: 1rem auto;

        .instagramLogo {
          width: 80%;
          height: 80%;
          background-color: transparent;
          position: relative;
          z-index: 0;
          overflow: hidden;
          border-radius: 50px;

          &::after {
            width: 500px;
            height: 500px;
            top: -6rem;
            left: -6rem;
          }

          .fa-instagram {
            font-size: 18rem;
            border-radius: 50px;
          }
        }

        .pseudo {
          font-size: 2rem;
        }
      }

      .contactDescription {
        font-size: 1.3rem;
        line-height: 2.3rem;

        .titleCtn {
          padding: 2rem 0;

          .title {
            font-size: 2.5rem;
          }

          .subTitle {
            font-size: 4rem;
            margin-top: -4rem;
          }
        }
      }
    }
  }
}

@media (min-width: 899px) {
  .contactCtn {
    .content {
      padding: 4rem 0;
      min-height: calc(100vh - 180px);

      .instagram .instagramLogo .fa-instagram {
        font-size: 16.5rem;
      }
    }
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .contactCtn {
    .headerCtn .navigationFit {
      width: 90%;
    }

    .content {
      padding: 2rem 0;
      min-height: calc(100vh - 200px);

      .instagram {
        width: 350px;
        height: 350px;
        margin: 4rem auto 0 auto;

        .instagramLogo {
          width: 80%;
          height: 80%;
          border-radius: 50px;

          &::after {
            width: 500px;
            height: 500px;
            top: -6rem;
            left: -7rem;
          }

          .fa-instagram {
            font-size: 15rem;
            border-radius: 50px;
          }
        }

        .pseudo {
          margin-top: 2rem;
        }
      }

      .contactDescription {
        width: 650px;
        font-size: 1.5rem;
        line-height: 2.5rem;

        .titleCtn {
          .title {
            font-size: 3rem;
          }

          .subTitle {
            font-size: 4rem;
            margin-top: -4rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1499px) {
  .contactCtn {
    .headerCtn .navigationFit {
      width: 90%;
    }

    .content {
      padding: 2rem 0;

      .instagram {
        width: 500px;
        height: 500px;
        margin: 4rem auto 0 auto;

        .instagramLogo {
          width: 80%;
          height: 80%;
          border-radius: 50px;

          &::after {
            width: 600px;
            height: 600px;
            top: -6rem;
            left: -7rem;
          }

          .fa-instagram {
            font-size: 20rem;
            border-radius: 50px;
            padding: 2rem 3.5rem;
          }
        }

        .pseudo {
          margin-top: 2rem;
        }
      }

      .contactDescription {
        width: 750px;
        font-size: 1.5rem;
        line-height: 2.5rem;

        .titleCtn {
          .title {
            font-size: 3rem;
          }

          .subTitle {
            font-size: 4rem;
            margin-top: -4rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1555px) {
  .contactCtn .content {
    padding: 3.5rem 0;

    .instagram {
      margin: 4rem 0 0 10rem;
    }
  }
}
