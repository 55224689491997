.scrollToTopBtn {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 50px;
  right: 10px;
  transition: all 0.3s ease;
  background-color: $c-charcoalDark;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;

  &:hover {
    background-color: $c-aliceBlue;

    .icon {
      color: $c-citrine;
    }
  }

  .icon {
    margin-top: 0.5rem;
    font-size: 1.5rem;
    color: $c-aliceBlue;
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  TABLETTE ------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 599px) {
  .scrollToTopBtn {
    width: 50px;
    height: 50px;
    right: 20px;

    .icon {
      font-size: 2rem;
    }
  }
}

@media (min-width: 899px) {
  .scrollToTopBtn {
    right: 30px;
  }
}

/*----------------------------------------------------------------------------------
-------------------------------------  DESKTOP -------------------------------------
----------------------------------------------------------------------------------*/

@media (min-width: 1199px) {
  .scrollToTopBtn {
    right: 70px;
  }
}
